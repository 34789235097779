<template>
  <div>
    <el-card shadow="always">
      <template #header>
        <el-row type="flex" justify="space-between" align="center">
          <el-col :md="12">
            <el-row type="flex" justify="start">
              <h4 class="m-0 mt-1">{{ PageTitle }}</h4>
            </el-row>
          </el-col>
          <el-col :md="12">
            <el-select
              filterable
              clearable
              remote
              :remote-method="searchClient"
              :loading="!clients"
              loading-text="Buscando clientes..."
              placeholder="Busque pelo nome, CPF ou CNPJ"
              @change="(v) => fetchClientByUid(v) | clearOrder(true)"
              v-model="order_.client_id"
              size="medium"
            >
              <template #prefix>Cliente:</template>
              <el-option
                v-for="item in Clients"
                :key="item.uid"
                :disabled="!item.is_active"
                :label="item.legal?.social_name || item.name"
                :value="item.uid"
              >
              </el-option> </el-select
          ></el-col>
        </el-row>
      </template>
      <el-row :gutter="4">
        <el-col>
          <el-card
            shadow="never"
            v-if="SelectedClient?.uid"
            class="m-1"
            :body-style="{ padding: '0px' }"
          >
            <template #default>
              <el-descriptions :column="2" border>
                <el-descriptions-item>
                  <template #label> Nome: </template>
                  {{
                    SelectedClient?.legal?.social_name || SelectedClient?.name
                  }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label> Razão social: </template>
                  {{
                    SelectedClient?.legal?.company_name || SelectedClient?.name
                  }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label> CPF/CNPJ: </template>
                  {{
                    SelectedClient?.legal?.cnpj ||
                    SelectedClient?.personal?.cpf_formatted
                  }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label> Proprietário/Responsável: </template>
                  {{ SelectedClient?.legal?.owner_name }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label> E-mail: </template>
                  {{ SelectedClient?.email }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label> Telefone: </template>
                  {{ SelectedClient?.contact?.phone }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template #label> Endereço </template>
                  {{ SelectedClient?.addr?.extended }}
                </el-descriptions-item>
              </el-descriptions>
            </template>
          </el-card>
          <el-empty
            v-else
            description="Selecione um cliente para iniciar"
            :image-size="35"
            class="p-0"
          ></el-empty>
        </el-col>
      </el-row>
      <el-row type="flex" justify="start">
        <h4>Detalhes do Pedido</h4>
      </el-row>
      <el-form :disabled="!SelectedClient?.uid">
        <el-space direction="vertical" style="width: 100%" fill>
          <el-row :gutter="4">
            <el-col :md="4" :sm="8">
              <el-date-picker
                v-model="deliver_at_date"
                placeholder="Data para entrega:"
                class="mt-1"
                type="date"
                :disabled-date="getDisabledDeliverDate"
                size="medium"
                format="DD/MM/YYYY"
              >
              </el-date-picker>
            </el-col>
            <el-col :md="4" :sm="8">
              <el-time-select
                v-model="deliver_at_hour"
                placeholder="Hora para entrega:"
                class="mt-1"
                start="07:00"
                step="00:30"
                end="18:30"
                size="medium"
                format="HH:MM"
              >
              </el-time-select>
            </el-col>
            <el-col :md="8" :sm="12">
              <el-select
                placeholder="Tipo parcelamento"
                v-model="order_.payment_method"
                size="medium"
              >
                <template #prefix>Faturamento:</template>
                <el-option
                  v-for="(item, id) in PaymentMethods"
                  :key="item"
                  :label="item"
                  :disabled="methodIsNotAllowedForClient(id)"
                  :value="id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :md="4" :sm="12">
              <base-input
                label="Parcelas"
                v-model="order_.total_payments"
                :disabled="!UserSelectedBillet"
                size="medium"
              >
              </base-input>
            </el-col>

            <el-col :md="4" :sm="12">
              <base-input
                v-model="order_.invoice_id"
                label="Romaneio"
                mask="#*"
              ></base-input>
            </el-col>
            <el-col :md="10">
              <el-select
                v-model="order_.seller_id"
                :loading="!sellers"
                :disabled="!AllowUserToSelectSeller"
                @visible-change="(v) => v && fetchSellers()"
                size="medium"
              >
                <template #prefix>Vendedor:</template>
                <el-option
                  v-for="seller in Sellers"
                  :key="seller"
                  :label="seller.name"
                  :value="seller.uid"
                ></el-option
              ></el-select>
            </el-col>
            <el-col :md="6">
              <el-select
                v-model="order_.invoice_config"
                :disabled="UserSelectedBillet"
                size="medium"
              >
                <template #prefix>NF:</template>
                <el-option
                  v-for="nf in InvoiceConfig"
                  :key="nf.value"
                  :label="nf.label"
                  :value="nf.value"
                ></el-option
              ></el-select>
            </el-col>
          </el-row>
          <el-divider class="mb-0"></el-divider>
          <div v-if="UserSelectedMoneyOrBillet">
            <el-row type="flex" justify="start">
              <h4>Parcelamento do Pedido</h4>
            </el-row>

            <el-descriptions v-if="OrderPayments?.length" :column="1" border>
              <el-descriptions-item v-for="(p, i) in OrderPayments" :key="p">
                <template #label> {{ i + 1 }}º vencimento: </template>
                <el-date-picker
                  v-model="expirations[i]"
                  placeholder="Vencimento:"
                  class="mt-1"
                  type="date"
                  :disabled-date="
                    (date) => !limitExpireAtOfOrderPayment(date, i)
                  "
                  size="medium"
                  format="DD/MM/YYYY"
                >
                </el-date-picker>
              </el-descriptions-item>
            </el-descriptions>
            <el-empty
              v-else
              description="Insira a quantidade de parcelas primeiro"
            ></el-empty>

            <el-divider class="mb-0"></el-divider>
          </div>
          <el-row type="flex" justify="start">
            <h4>Cadastro de Itens</h4>
          </el-row>
          <el-row :gutter="4">
            <el-col :md="8">
              <el-select
                filterable
                remote
                v-model="selectedProduct"
                :loading="!products"
                value-key="uid"
                loading-text="Buscando produtos..."
                :remote-method="searchProduct"
                size="medium"
              >
                <template #prefix>Produto:</template>
                <el-option
                  v-for="item in Products"
                  :disabled="!item.is_active"
                  :key="item.uid"
                  :label="item?.product?.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :md="5">
              <base-input
                v-model="order_.quantity"
                type="money"
                :minimumFractionDigits="3"
                label="Qtd:"
              ></base-input>
            </el-col>
            <el-col :md="4">
              <el-select v-model="order_.un_quantity" size="medium">
                <template #prefix>Un. Medida:</template>
                <el-option
                  v-for="item in ['Peso', 'Peça']"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-col>

            <el-col :md="7">
              <base-input v-model="order_.amount" type="money" label="Preço:">
                <template #append v-if="selectedProduct?.price">
                  Ref:
                  {{
                    currencyFormatter.format(
                      Number(selectedProduct?.price?.female_std)
                    )
                  }}
                </template>
                <template
                  #prefix
                  v-if="order_.amount && selectedProduct?.price"
                >
                  <el-icon
                    size="small"
                    class="el-icon-arrow-down"
                    color="red"
                    style="line-height: 36px"
                    v-if="
                      Number(order_.amount) <
                      Number(selectedProduct?.price?.female_std)
                    "
                  ></el-icon>
                  <el-icon
                    size="small"
                    class="el-icon-arrow-up"
                    color="green"
                    style="line-height: 36px"
                    v-else
                  ></el-icon>
                </template>
              </base-input>
            </el-col>
          </el-row>

          <el-row :gutter="4" type="flex">
            <el-col :md="16" :xs="24">
              <base-input
                v-model="order_.product_obs"
                label="Observação:"
              ></base-input
            ></el-col>
            <el-col :md="8" :xd="24">
              <el-select size="medium" v-model="order_.storage">
                <el-option
                  v-for="s in StorageProperties"
                  :key="s"
                  :value="s.value"
                  :label="s.name"
                ></el-option> </el-select
            ></el-col>
          </el-row>

          <el-row type="flex" justify="end">
            <el-button type="primary" @click="addProductToOrder" size="medium">
              Adicionar Produto
            </el-button>
          </el-row>
        </el-space>
        <el-divider></el-divider>

        <el-table
          stripe
          :cell-style="() => 'text-align:center;'"
          :data="order_?.products || []"
          style="width: 100%; z-index: 0"
        >
          <el-table-column
            label="produto"
            prop="product.name"
          ></el-table-column>
          <el-table-column
            label="qtd."
            :formatter="
              (r) => `${weightFormatter.format(Number(r.quantity) || 0)}`
            "
          ></el-table-column>
          <el-table-column label="un" prop="un_quantity"></el-table-column>
          <el-table-column
            label="valor"
            prop="amount"
            :formatter="(r) => currencyFormatter.format(r.amount)"
          ></el-table-column>
          <el-table-column
            label="total"
            prop="total_amount"
            :formatter="(r) => currencyFormatter.format(r.total_amount)"
          ></el-table-column>
          <el-table-column label="obs." prop="obs"></el-table-column>
          <el-table-column>
            <template #default="c">
              <el-row type="flex" justify="end">
                <el-button
                  type="danger"
                  size="small"
                  plain
                  icon="el-icon-delete"
                  @click="removeProductFromOrder(c.row)"
                  circle
                >
                </el-button>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-card>
    <el-affix position="bottom" :offset="0">
      <el-card shadow="never">
        <el-row type="flex" justify="space-between">
          <el-descriptions :column="3" border>
            <el-descriptions-item>
              <template #label> Crédito do Cliente: </template>
              <span :class="SelectedClientCredit < 0 ? 'danger' : 'success'">
                {{
                  SelectedClientCredit
                    ? currencyFormatter.format(Math.abs(SelectedClientCredit))
                    : "Ilimitado"
                }}
              </span>
            </el-descriptions-item>
            <el-descriptions-item v-if="canAny('FinalizeProductionOrder')">
              <template #label> Pedidos em Produção: </template>
              <span>
                {{ countOrdersByStatus(["created", "production"]) }}
              </span>
            </el-descriptions-item>
            <el-descriptions-item v-if="canAny('FinalizeProductionOrder')">
              <template #label> Pedidos em Análise: </template>
              <span>
                {{ countOrdersByStatus(["analisys"]) }}
              </span>
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label> Total do Pedido: </template>

              <span
                :class="{
                  'client-warning': TotalAmountOrder > SelectedClientCredit,
                }"
              >
                {{ currencyFormatter.format(TotalAmountOrder) }}
              </span>
            </el-descriptions-item>
            <el-descriptions-item v-if="canAny('FinalizeProductionOrder')">
              <template #label> Pedidos em Entrega: </template>
              <span
                :class="{
                  'client-warning': !!countOrdersByStatus([
                    'ready',
                    'delivery',
                  ]),
                }"
              >
                {{ countOrdersByStatus(["ready", "delivery"]) }}
              </span>
            </el-descriptions-item>
            <el-descriptions-item v-if="canAny('FinalizeProductionOrder')">
              <template #label> Boletos Vencidos: </template>
              <span
                :class="{
                  'client-warning': !!SelectedClient?.expired_billets?.length,
                }"
              >
                {{ SelectedClient?.expired_billets?.length }}
              </span>
            </el-descriptions-item>
            <el-descriptions-item v-if="canAny('FinalizeProductionOrder')">
              <template #label> Boletos em Aberto: </template>
              <span
                :class="{
                  'client-warning': !!SelectedClient?.opened_billets?.length,
                }"
              >
                {{ SelectedClient?.opened_billets?.length }}
              </span>
            </el-descriptions-item>
            <el-descriptions-item v-if="canAny('FinalizeProductionOrder')">
              <template #label> Valores em aberto: </template>
              <span
                :class="{
                  'client-warning': !!sumDebtsAmount(SelectedClient?.debts),
                }"
              >
                {{
                  currencyFormatter.format(
                    sumDebtsAmount(SelectedClient?.debts)
                  )
                }}
              </span>
            </el-descriptions-item>
          </el-descriptions>
          <el-row justify="end" class="mt-1" type="flex">
            <el-popconfirm
              title="Limpar todos os campos do pedido?"
              @confirm="clearOrder()"
            >
              <template #reference>
                <el-button type="text" class="button text-muted"
                  >Cancelar</el-button
                >
              </template>
            </el-popconfirm>
            <el-button
              :type="!!getClientPendency() ? 'danger' : 'primary'"
              class="button"
              @click="createOrder"
              :loading="isLoadingSave"
              :disabled="
                !canAny('CreateOrderWithPendency') && !!getClientPendency()
              "
              :title="getClientPendency()"
              >Criar pré-pedido</el-button
            >
          </el-row>
        </el-row>
      </el-card>
    </el-affix>
  </div>
</template>

<script>
import { ElNotification } from "element-plus";
import BaseInput from "../components/BaseInput.vue";
import Sellers from "../services/sellers";
import OrderService from "../services/orders";
import PartnerService from "../services/partners";
import ProductService from "../services/products";
import {
  currencyFormatter,
  numberFormatter,
  weightFormatter,
} from "../utils/formatters";
import { notifySuccess, notifyError } from "../utils/notifiers";
export default {
  name: "PaymentOrders",
  components: { BaseInput },
  data: () => ({
    hasError: false,
    clientCpfCnpj: "",
    products: null,
    sellers: null,
    isLoadingSave: false,
    expirations: {},
    selectedProduct: null,
    order_: {},
    orderError: "",
    client: null,
    deliver_at_date: null,
    deliver_at_hour: null,
    clients: null,
    dateFormatter: new Intl.DateTimeFormat("pt-BR"),
    currencyFormatter,
    numberFormatter,
    cnpj: {},
    weightFormatter,
  }),
  mounted() {
    //this.fetchPaymentOrders();
    if (this.getOrder()?.client_id) {
      this.fetchClientByUid(this.getOrder().client_id);
      this.fetchProductByUid(this.getOrder().product_id);
      this.fetchSellers();
      this.$confirm("Você tem um pedido salvo, deseja recuperar?", {
        confirmButtonText: "Recuperar",
        cancelButtonText: "Novo pedido",
        type: "warning",
      })
        .then(() => {
          this.order_ = this.getOrder();
          this.expirations = JSON.parse(
            localStorage.getItem("expirations") || "{}"
          );
          const { date, hour } = JSON.parse(
            localStorage.getItem("deliver_at") || "{}"
          );

          this.deliver_at_date = date ? new Date(date) : null;
          this.deliver_at_hour = hour || null;
        })
        .catch(() => {
          this.client = null;
        });
    }

    this.$watch(
      function () {
        return Object.values(this.order_);
      },
      function () {
        if (this.$store.state.user.seller) {
          this.order_.seller_id = this.$store.state.user.seller.uid;
          this.sellers = [this.$store.state.user.seller];
        }
        this.saveOrder(this.order_);
      },
      { deep: true }
    );
  },
  watch: {
    "order_.product_id": function (uid) {
      if (this.SelectedProduct?.properties?.length) {
        this.order_.storage = this.SelectedProduct.properties.find(
          (e) => e.type === "storage"
        )?.name;
        this.order_.composition = this.SelectedProduct.properties.find(
          (e) => e.type === "composition"
        )?.name;
        this.order_.cutting = this.SelectedProduct.properties.find(
          (e) => e.type === "cutting"
        )?.name;
      }

      if (this.isProductAlreadyIncluded({ uid })) {
        const product = this.order_?.products?.find((p) => p.uid === uid);
        this.order_.amount = product.amount;
        this.order_.weight = product.weight;
        this.order_.composition = product.composition;
        this.order_.storage = product.storage;
        this.order_.cutting = product.cutting;
      }

      this.order_.sex = "female";
    },
    "order_.total_payments": function () {
      if (this.UserSelectedMoney && this.order_.total_payments != 1)
        this.order_.total_payments = 1;

      if (
        this.UserSelectedBillet &&
        Number(this.order_.total_payments) >
          Number(this.SelectedClient?.max_billets_payments)
      )
        this.order_.total_payments = this.SelectedClient?.max_billets_payments;
    },
    "order_.payment_method": function () {
      this.order_.total_payments = 1;
    },
    "order_.amount": function () {
      this.order_.total_amount =
        (this.order_.amount || 0) * (this.order_.weight || 0);
    },
    "order_.weight": function () {
      this.order_.total_amount =
        (this.order_.amount || 0) * (this.order_.weight || 0);
    },
    order_: function () {
      if (!this.order_.total_payments) this.order_.total_payments = 1;
    },
    selectedProduct: function () {
      this.order_.un_quantity = "Peso";
    },
    UserSelectedBillet() {
      this.order_.invoice_config = 1;
    },
    deliver_at_hour: function (v) {
      if (v) {
        const [hour, minute] = v?.split(":");

        if (!this.order_.deliver_at) this.order_.deliver_at = new Date();

        this.order_.deliver_at.setHours(hour, minute, 0, 0);
      }
    },
    deliver_at_date: function (v) {
      if (!this.order_.deliver_at) this.order_.deliver_at = new Date();

      this.order_.deliver_at = new Date(
        this.extractDatePortionOfString(v) +
          "T" +
          (this.deliver_at_hour || "00:00")
      );
    },
  },
  computed: {
    IsPublicSell() {
      return this.$route.params.orderType === "licitacao";
    },
    AllowUserToSelectSeller() {
      return !this.$store.state.user.seller;
    },
    AllowUserSelectMultipleTerms() {
      return this.UserSelectedBillet;
    },
    Expirations() {
      return Object.values(this.expirations || {});
    },
    UserSelectedBillet() {
      return this.order_.payment_method === "billet";
    },
    UserSelectedMoney() {
      return this.order_.payment_method === "cash";
    },
    OrderPayments() {
      if (this.order_.total_payments) {
        return Array.from({ length: this.order_.total_payments }).map(
          () => ({})
        );
      } else return [];
    },
    UserSelectedMoneyOrBillet() {
      return this.UserSelectedBillet || this.UserSelectedMoney;
    },
    ShowToolTip() {
      return !!this.SelectedProduct?.price;
    },
    SelectedClient() {
      return this.client || this.order_.client;
    },
    Sellers() {
      return this.sellers || [];
    },
    StorageProperties() {
      return [
        { name: "Resfriado", value: "cold" },
        { name: "Congelado", value: "freezed" },
      ];
    },
    InvoiceConfig() {
      return this.$store.state.constants.invoice_config;
    },
    SelectedClientCredit() {
      const total_credit = Number(this.SelectedClient?.credit?.total_amount);
      const inuse_credit_orders = this.sumOrderTotalAmounts(
        this.SelectedClient?.openedOrders
      );
      const inuse_credit_debts = this.sumDebtsAmount(
        this.SelectedClient?.debts
      );
      if (!total_credit) return total_credit;
      return total_credit - inuse_credit_orders - inuse_credit_debts;
    },
    SelectedProduct() {
      return this.selectedProduct;
    },
    TotalAmountOrder() {
      const total = this.order_.products?.reduce(
        (t, e) => (t += Number(e.total_amount)),
        0
      );
      return total || 0;
    },
    PageTitle() {
      return this.IsPublicSell
        ? "Lançar pedido pra licitação"
        : this.$route.name;
    },
    Products() {
      return this.mountedProducts() || [];
    },
    Clients() {
      return this.clients || [];
    },
    AcceptedTerms() {
      return this.SelectedClient?.payment_days?.map((d) => {
        if (d > 0)
          return {
            label: `${d} dias`,
            value: d,
          };
        else return { label: "A vista", value: 0 };
      });
    },
    AcceptedBilletsTerms() {
      return this.SelectedClient?.payment_days_billets?.map((d) => {
        if (d > 0)
          return {
            label: `${d} dias`,
            value: d,
          };
        else return { label: "A vista", value: 0 };
      });
    },
    PaymentTerms() {
      return this.AllowUserSelectMultipleTerms
        ? this.AcceptedBilletsTerms
        : this.AcceptedTerms;
    },
    Can() {
      return this.$store.state.auth.can;
    },
    PaymentMethods() {
      return {
        cash: "Crediário / Vencimento (no prazo)",
        money: "Dinheiro (a vista)",
        credit: "Cartão de Crédito (a vista)",
        debit: "Cartão de Débito (a vista)",
        billet: "Boleto (no prazo)",
        check: "Cheque (a vista)",
        pix: "PIX (a vista)",
      };
    },
  },
  methods: {
    sumDebtsAmount(debts) {
      return debts?.reduce((t, d) => (t += Number(d.amount)), 0) || 0;
    },
    countOrdersByStatus(statuses) {
      return this.SelectedClient?.openedOrders?.filter(
        (o) => !!statuses?.find((_) => _ === o.status)
      )?.length;
    },
    termIsAllowedForClient(term) {
      return !!this.SelectedClient?.payment_days?.find(
        (p) => Number(p) === Number(term)
      );
    },
    getPaymentDaysBySelectedMethod() {
      return this.order_.payment_method === "billet"
        ? this.SelectedClient?.payment_days_billets
        : this.SelectedClient?.payment_days;
    },
    limitExpireAtOfOrderPayment(date, paymentIndex) {
      const now = this.order_.deliver_at || new Date();
      const dayInMs = 8.64e7;
      now.setHours(0, 0, 0, 0);

      const lastPayment = this.expirations[paymentIndex - 1];
      const nextPayment = this.expirations[paymentIndex + 1];

      const min_expiration = lastPayment || now;

      const payment_days = this.getPaymentDaysBySelectedMethod();
      const payment_days_in_ms = payment_days * dayInMs;

      const max_expiration =
        nextPayment || new Date(now.valueOf() + payment_days_in_ms);

      return (
        date.valueOf() >= min_expiration.valueOf() + dayInMs &&
        date <= max_expiration - dayInMs
      );
    },
    getClientPendency() {
      const MIN_SCORE = 50;
      const MAX_DEBT = 0;
      const MAX_OPEN_ORDERS = 2;
      const MIN_CAPITAL = 5000;
      const TOTAL_CREDIT = Number(this.SelectedClient?.credit?.total_amount);

      if (this.SelectedClient?.score?.number < MIN_SCORE)
        return "O score de crédito do cliente está abaixo do mínimo permitido";
      if (this.SelectedClient?.expired_billets?.length > MAX_DEBT)
        return "O cliente possui boletos vencidos";
      if (
        TOTAL_CREDIT &&
        TOTAL_CREDIT - this.sumTotalDebts(this.SelectedClient?.debts) <
          this.order_.total_amount
      )
        return "O cliente não possui crédito suficiente para novo pedido";
      if (this.SelectedClient?.openedOrders?.length > MAX_OPEN_ORDERS)
        return "O cliente já possui outros pedidos em produção";

      if (this.cnpj?.capital && this.cnpj.capital < MIN_CAPITAL)
        return "O capital social do cliente é menor do que o mínimo";

      return null;
    },
    sumOrderTotalAmounts(orders) {
      return orders?.reduce((t, e) => t + Number(e.total_amount), 0) || 0;
    },
    sumTotalDebts(debts) {
      return debts?.reduce((t, d) => t + Number(d.amount), 0) || 0;
    },
    getDisabledDeliverDate(time) {
      const todayDate = new Date();
      todayDate.setHours(0, 0, 0);
      time.setHours(23, 59, 59);
      return this.canAny("CreateLongOrder")
        ? false
        : time.getTime() < todayDate.getTime() ||
            time.getTime() >= todayDate.getTime() + 864e5 * 5;
    },
    methodIsNotAllowedForClient(method) {
      return !this.SelectedClient?.accepted_payments?.find((a) => a === method);
    },
    extractDatePortionOfString(date) {
      return date?.toISOString()?.slice(0, 10);
    },
    mountedProducts() {
      return (
        this.products
          ?.map((p) =>
            p?.mounted
              .map((m) => ({
                ...p,
                ...(m?.codes || {}),
                is_normal_sell_available: m.is_normal_sell_available,
                uid: m.id,
                price: m?.price,
                productUid: p.uid,
                product: {
                  name: `${p.name} ${m.cutting ?? ""} ${m.composition ?? ""} ${
                    m.storage ?? ""
                  }`,
                },
              }))
              ?.flat()
              ?.filter(({is_normal_sell_available}) => !!is_normal_sell_available)
          )
          ?.flat() || []
      );
    },
    async fetchSellers() {
      const { sellers, error } = await Sellers().index();

      if (error) {
        this.sellers = null;
        this.hasError = true;
      } else {
        this.sellers = sellers;
      }
    },
    canAny() {
      for (let a in arguments) if (this.Can(arguments[a])) return true;

      return false;
    },
    saveOrder(order) {
      localStorage.setItem("order", order ? JSON.stringify(order) : order);
      localStorage.setItem(
        "expirations",
        JSON.stringify(this.expirations || {})
      );
      localStorage.setItem(
        "deliver_at",
        JSON.stringify({
          date: this.deliver_at_date,
          hour: this.deliver_at_hour,
        })
      );
    },
    getOrder() {
      return JSON.parse(localStorage.getItem("order"));
    },
    removeProductFromOrder(product) {
      this.order_.products = this.order_?.products.filter(
        (p) => p.uid != product.uid
      );
    },
    hasEmptyInputs() {
      return (
        !this.selectedProduct || !this.order_.quantity || !this.order_.amount
      );
    },
    async fetchClientByUid(uid) {
      const { partner } = await PartnerService(uid).get();

      if (partner) {
        this.clients = [partner];
        this.client = partner;
      }

      if (partner.type === "legal")
        this.fetchClientAPICNPJ(onlyNumbers(partner.legal?.cnpj));
    },
    expirationsIsEmpty() {
      return this.Expirations?.filter(
        (e, i) => i < this.order_.total_payments
      )?.some((e) => !e);
    },
    fetchProductByUid(uid) {
      const url = new URL(`${this.$store.state.apiUrl}products/${uid}`);
      if (uid)
        fetch(url, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => (this.products = [json]));
    },
    clearOrder(keepClient) {
      if (keepClient) this.order_ = { client_id: this.order_.client_id };
      else {
        this.clients = [];
        this.client = null;
        this.order_ = {};
      }
      this.saveOrder(null);
      this.selectedProduct = "";
      this.expirations = {};
      this.deliver_at_date = null;
      this.deliver_at_hour = null;
      this.orderError = "";
    },
    setLoading(v) {
      this.isLoadingSave = v;
    },
    checkIfAnyProductHasLowerPrice() {
      return !!this.order_.products;
    },
    userSelectedAllTerms() {
      return (
        this.order_.payment_days &&
        this.order_.payment_days?.length === this.order_.total_payments
      );
    },
    checkProblems() {
      if (this.UserSelectedMoneyOrBillet && this.expirationsIsEmpty())
        return "Insira todos os vencimentos de parcelas";

      if (!this.order_?.products?.length)
        return "Você não pode criar um pedido sem cadastrar um produto";

      if (!this.order_.client_id)
        return "Você não pode criar um pedido sem selecionar um cliente";

      return false;
    },
    async createOrder() {
      if (this.checkProblems()) return notifyError(this.checkProblems());
      this.setLoading(true);
      if (this.orderError || !!this.getClientPendency()) {
        this.order_.status = "analisys";
        this.order_.errors = this.orderError || this.getClientPendency();
      }
      const { order, error } = await OrderService().create({
        ...this.order_,
        total_amount: this.TotalAmountOrder,
      });

      if (order) {
        notifySuccess("Pedido criado com sucesso");

        await this.createOrderPayments(order);

        this.clearOrder();
      } else notifyError("Ocorreu um erro", error.message);

      this.setLoading(false);
    },
    async createOrderPayments(order) {
      if (this.UserSelectedMoneyOrBillet) {
        const queries = this.Expirations.filter(
          (e, i) => i < this.order_.total_payments
        );

        for (let e in queries)
          await OrderService(order.uid).Payments().create({
            obs: "Pagamento cadastrado automaticamente no momento do cadastro do pedido",
            expires_at: queries[e],
            payment_method: this.order_.payment_method,
          });
      }
    },
    shouldSumTotalAmount() {
      return this.order_.un_quantity === "Peso";
    },
    addProductToOrder() {
      if (!Array.isArray(this.order_?.products)) this.order_.products = [];

      if (this.hasEmptyInputs())
        return ElNotification.error({
          title: "Não é possível adicionar este produto",
          message:
            "Informe corretamente um produto, peso e preço antes de adicionar",
        });

      if (this.shouldSumTotalAmount())
        this.order_.total_amount =
          Number(this.order_.quantity) * Number(this.order_.amount) || 0;

      this.order_?.products.push({
        ...(this.SelectedProduct || {}),
        amount: this.order_.amount,
        composition: this.order_.composition,
        storage: this.order_.storage,
        cutting: this.order_.cutting,
        quantity: this.order_.quantity,
        un_quantity: this.order_.un_quantity,
        total_amount: this.order_.total_amount,
        obs: this.order_.product_obs,
        qrcode: this.SelectedProduct?.qrcode,
        ncm:
          this.order_.storage === "freezed"
            ? this.SelectedProduct?.ncm_frozen
            : this.SelectedProduct?.ncm_chilled,
        gtim: this.SelectedProduct?.gtim,
        ean: this.SelectedProduct?.ean,
      });

      if (this.productHasLowerPrice()) {
        this.orderError += `Produto ${this.selectedProduct.name} com preço menor que o mínimo`;
      }

      if (this.productDontHaveNCM()) {
        this.orderError += `Produto ${this.selectedProduct.name} não possui NCM cadastrado`;
      }

      this.clearProductFormInput();
    },
    clearProductFormInput() {
      this.order_.quantity =
        this.order_.amount =
        this.selectedProduct =
        this.order_.product_obs =
          null;
    },
    productDontHaveNCM() {
      return this.order_.storage === "freezed"
        ? !this.SelectedProduct?.ncm_frozen
        : !this.SelectedProduct?.ncm_chilled;
    },
    productHasLowerPrice() {
      return (
        Number(this.order_.amount) <
        Number(this.selectedProduct?.price?.female_std)
      );
    },
    isProductAlreadyIncluded(product) {
      if (Array.isArray(this.order_.products))
        return this.order_.products.some((p) => p.product.uid === product.uid);

      return false;
    },
    getProperties(type) {
      return (
        this.SelectedProduct?.category?.properties?.filter(
          (p) => p.type === type
        ) || []
      );
    },
    hasProperty(name) {
      return this.SelectedProduct?.properties?.some((p) => p.name === name);
    },
    openPaymentOrderModal(e) {
      (this.payment_order = e), (this.showPaymentOrderModal = true);
    },
    openFilterPaymentOrderModal(e) {
      (this.payment_order = e), (this.showFilterPaymentOrderModal = true);
    },
    async searchClient(name) {
      const params = { is_client: true, is_active: true };

      if (isNumber(name)) params["searchCpfCnpj"] = name;
      else params["searchName"] = name;

      const { partners } = await PartnerService().index(params);

      if (partners) this.clients = partners;
      else this.clients = [];

      this.isLoading = false;
    },
    async fetchClientAPICNPJ(_cnpj) {
      const { cnpj } = await PartnerService().API(_cnpj).get({ api: "CNPJA" });

      if (cnpj) this.cnpj = cnpj;
    },
    async searchProduct(searchName) {
      this.products = null;
      
      const {products} = await ProductService().index({
        searchName,
        is_normal_sell_available: true,
      });

      if(products)
        this.products = products;

      this.isLoading = false;
    },
    getBorderColorOfInputAmount() {
      const correctColor = "#ebeef5";
      const failColor = "red";

      switch (this.order_.sex) {
        case "male":
          if (
            this.order_.amount < Number(this.SelectedProduct?.price?.male_min)
          )
            return failColor;
          else return correctColor;
        case "female":
          if (
            this.order_.amount < Number(this.SelectedProduct?.price?.female_min)
          )
            return failColor;
          else return correctColor;
        default:
          return correctColor;
      }
    },
  },
};
const isNumber = (val) => /^[0-9./-]*$/.test(val);
const onlyNumbers = (s) => `${s}`.replace(/[^0-9]/g, "");
</script>
<style scoped>
.el-card {
  width: 100%;
}
.p-0 {
  padding: 0px !important;
}
.m-0 {
  margin: 0px !important;
}
.p-1 {
  padding: 4px !important;
}
.m-1 {
  margin-top: 5px !important;
}
.mt-1 {
  margin-top: 5px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.has-error {
  border-color: red !important;
}
.checkbox-border {
  border-left: 1px solid #dcdfe6 !important;
}
.mx-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.el-descriptions .client-warning {
  font-weight: 600;
  color: red;
}
</style>
